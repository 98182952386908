import { updateUrl, updateUrlWithoutReload } from "@/utils/updateUrl";

export const paginationMixin = {
  methods: {
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    // 注意，updateUrl 會導致頁面重新渲染（component 會重新載入），如果不想要重載，改用 handlePageResetWithoutReload
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
    handlePageResetWithoutReload() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrlWithoutReload(this.query, this.$store, this.$route);
      } else {
        this.query.page = 1;
      }
    },
  },
};
