import https from "./https"
// import store from "@/store"

const partnerApi = {
  getPartners (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partners'
    const searchParams = new URLSearchParams(params);
    return https.get(`${url}?${searchParams}`);
    // return https.get(url, { params })
  },
  getPartner (organization, partnerWorkerId) {
    const url = 'admin/organizations/' + organization.id  + '/partners/' + partnerWorkerId
    return https.get(url)
  },
  updatePartner (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partners/' + params.id;
    return https.put(url, params)
  },
  removePartner (organization, partnerWorkerId) {
    const url = 'admin/organizations/' + organization.id + '/partners/' + partnerWorkerId;
    return https.delete(url)
  },
  createPartner (organization, params) {
    const url = 'admin/organizations/' + organization.id  + '/partners'
    return https.post(url, params)
  },
  getStatusOptions (organization) {
    const url = 'admin/organizations/' + organization.id  + '/partners/status-options'
    return https.get(url)
  },
  uploadPartner (organization, formData) {
    const url = `admin/organizations/${organization.id}/partners/import`

    return https.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  exportPartner (organization, params) {
    const url = `admin/organizations/${organization.id}/partners/export`
    const searchParams = new URLSearchParams(params);
    return https.get(`${url}?${searchParams}`);
  },
}

export default partnerApi
