export const twCities = [
  {
    text: '新北市',
    value: '新北市',
  },
  {
    text: '台北市',
    value: '台北市',
  },
  {
    text: '桃園市',
    value: '桃園市',
  },
  {
    text: '台中市',
    value: '台中市',
  },
  {
    text: '台南市',
    value: '台南市',
  },
  {
    text: '高雄市',
    value: '高雄市',
  },
  {
    text: '基隆市',
    value: '基隆市',
  },
  {
    text: '新竹市',
    value: '新竹市',
  },
  {
    text: '嘉義市',
    value: '嘉義市',
  },
  {
    text: '新竹縣',
    value: '新竹縣',
  },
  {
    text: '苗栗縣',
    value: '苗栗縣',
  },
  {
    text: '彰化縣',
    value: '彰化縣',
  },
  {
    text: '南投縣',
    value: '南投縣',
  },
  {
    text: '雲林縣',
    value: '雲林縣',
  },
  {
    text: '嘉義縣',
    value: '嘉義縣',
  },
  {
    text: '屏東縣',
    value: '屏東縣',
  },
  {
    text: '宜蘭縣',
    value: '宜蘭縣',
  },
  {
    text: '花蓮縣',
    value: '花蓮縣',
  },
  {
    text: '台東縣',
    value: '台東縣',
  },
  {
    text: '澎湖縣',
    value: '澎湖縣',
  },
  {
    text: '金門縣',
    value: '金門縣',
  },
  {
    text: '連江縣',
    value: '連江縣',
  },
];
